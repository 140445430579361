import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import settings from "../settings"
import "./itinerary-view.scss"
import Seo from "../components/seo"
import ScrollToTop from "react-scroll-to-top"

export default function Articles({ data }) {
  const content = JSON.parse(data.mysqlArticles.content)
  return (

    <Layout>
      <ScrollToTop smooth />
      <Seo title={content.primaryInfo.title + "| iwant2explore.com | visa2explore"} description={content.primaryInfo.metadescription} keywords={content.primaryInfo.metakeyword} />

      <div className="section">
        <Container fluid>
          <Container className="article">
            <Row className="justify-content-md-center">
              <Col lg={8}>
                <h1>{content.primaryInfo.title}</h1>

                {content.pageContent.map((block, i) => {
                  if (block.type === "CONTENT") {
                    return <ContentBlock key={"content_block_" + i} data={block} />
                  } else if (block.type === "IMAGE") {
                    return <ImageBlock key={"image_block_" + i} data={block} />
                  }
                })}
              </Col>
              <Col lg={4}>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
query ($uri: String!) {
  mysqlArticles(slug: {eq: $uri}) {
    slug
    content
  }
}
`
const ContentBlock = ({ data }) => {
  return (
    <div className="contentblock">
      <h2>{data.heading}</h2>
      <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
    </div>
  )
}

function ImageBlock({ data }) {
  return (
    <Row className="image-block">
      {data.images.map((image, i) => {
        return (
          <SimpleReactLightbox>
          <SRLWrapper options={{buttons:{showDownloadButton:false}}}>
          <Col key={"image_" + i} style={{display: i>0?"none":"block"}}>
            <img src={settings.IMAGE_URL + image.imageData.publicUrl+".webp"}  alt={image.imageData.caption}/>
            <SeeMore/>
            <Caption image={image.imageData}/>
          </Col>
        </SRLWrapper>
      </SimpleReactLightbox>
        )
      })}
    </Row>
  )
}
const SeeMore =()=>{
  return  <div className="btnSeeMore" onClick={(e)=>{
    e.preventDefault();
    e.currentTarget.parentElement.getElementsByTagName("img")[0].click()
  }}>See More</div>
}

const Caption = (data)=>{
  const image = data.image;
  return ( <div className="row">
    <div className="col">
      <p><i>{image.caption}</i></p>
    </div>
    {(image.ownerUrl || image.ownerName) && (
      <div className="col">
        <p className="attribute">
          <i>
            {image.ownerUrl && ( <a href={image.ownerUrl} target="_blank">
              &copy; {image.ownerName}</a>)}
          </i>
        </p>
      </div>

    )}

  </div>)

}

